import React from 'react';

import styled from 'styled-components';

import { imgixNsfasLogoUrl } from 'config/imgix';
import { imgixVarsityCollegeLogoUrl } from 'config/imgix';
import { imgixAIELogoUrl } from 'config/imgix';
import theme from 'config/theme';
import routes from 'config/routes';

const { boxShadow, colors } = theme;

interface VerificationContainerProps {
	count: number;
	onClick: () => void;
}

interface VerificationProps {
	src: string;
	size: number;
}

interface ListingVerificationsV2Props {
	nsfasAccredited?: boolean;
	varsityCollegeAccredited?: boolean;
	aieAccredited?: boolean;
}

const VerificationContainer = styled.div<VerificationContainerProps>`
	margin-right: 8px;
	min-width: ${({ count }) => `${(count - 1) * 16 + 36}px`};
`;

const VerificationWrapper = styled.div`
	width: 16px;
	display: inline - block;
`;

const Verification = styled.div<VerificationProps>`
	background-image: url("${({ src }) => src}");
	background-size: ${({ size }) => size}px;
	background-position: center;
	width: 36px;
	height: 36px;
	background-color: ${colors.white};
	border: 1px solid ${colors.grey10};
	box-shadow: ${boxShadow.small};
	border-radius: 50%;
`;

const handleVerificationsClick = () => {
	window.open(routes.verificationsDisclaimer);
};

const ListingVerificationsV2 = ({
	nsfasAccredited,
	varsityCollegeAccredited,
	aieAccredited,
}: ListingVerificationsV2Props) => {
	return (
		<VerificationContainer
			onClick={handleVerificationsClick}
			count={+(nsfasAccredited || 0) + +(varsityCollegeAccredited || 0) + +(aieAccredited || 0)}
		>
			{!!aieAccredited && (
				<VerificationWrapper>
					<Verification size={25} src={imgixAIELogoUrl} />
				</VerificationWrapper>
			)}
			{!!varsityCollegeAccredited && (
				<VerificationWrapper>
					<Verification size={25} src={imgixVarsityCollegeLogoUrl} />
				</VerificationWrapper>
			)}
			{!!nsfasAccredited && (
				<VerificationWrapper>
					<Verification size={27} src={imgixNsfasLogoUrl} />
				</VerificationWrapper>
			)}
		</VerificationContainer>
	);
};

export default ListingVerificationsV2;
