import styled from 'styled-components';
import theme from 'config/theme';
const { colors } = theme;

export const Container = styled.div`
	margin: 24px 24px 24px;
`;

export const ListingsContainer = styled.div`
	display: flex;
	justify-content: center;
	overflow: auto;
	justify-content: flex-start;
	margin: 0 0;
`;

export const Listings = styled.div`
	display: flex;
	padding: 0 8px;
`;

export const ListingsSectionHeader = styled.div`
	margin-top: 16px;
	width: 100%;
	height: 320px;
	padding: 0 16px;
	justify-content: center;
	text-align: center;
	line-height: 1.2em;
`;

export const ListingsSectionTitle = styled.div`
	margin-bottom: 8px;
	width: 100%;
	font-weight: 700;
	font-size: 20px;
	margin-top: 100px;
	left: 50%;
	margin-right: -50%;
	color: ${colors.grey60};
`;
