import ListingCardMobile from 'components/digs-search-v2/ListingCardMobile';
import React from 'react';
import { Button } from 'lib/Button';
import { Listing } from 'models/listing';
import { analyticsService, EventName } from 'services/analyticsService';
import { listingService } from 'services/listingService';
import { Listings, ListingsContainer, ListingsSectionHeader, ListingsSectionTitle } from './styles';

interface PartnersListingsAccordionProps {
	listings: Listing[];
	hasZeroListings?: boolean;
	locations?: number;
	university?: number;
}

const PartnersListingsAccordion = ({
	listings,
	hasZeroListings,
	locations,
	university,
}: PartnersListingsAccordionProps) => {
	const handleListingCardClick = (listing: Listing) => {
		const link = listingService.getViewListingLink(listing);
		analyticsService.trackEvent(EventName.PartnersListingClick, {
			partners_listing_id: listing.uuid,
		});
		window.open(link, '_blank');
	};

	const handleEmptyButtonClick = () => {
		let url = 'digs-search';
		if (!!university) {
			url = `/digs-search?university=${university}`;
		} else if (!!locations) {
			url = `/digs-search?locations=${locations}`;
		}
		window.open(url, '_blank');
	};

	return (
		<>
			<ListingsContainer>
				<Listings>
					{listings.map((listing, i) => (
						<ListingCardMobile
							key={listing.uuid}
							{...listing}
							onClick={() => handleListingCardClick(listing)}
							widthPx={280}
							fixedHeightPx={370}
							hideFavourite
						/>
					))}
				</Listings>
			</ListingsContainer>
			{hasZeroListings && (
				<ListingsSectionHeader>
					<ListingsSectionTitle>No accredited properties currently available</ListingsSectionTitle>
					<br />
					<Button isPink={true} isLoading={false} onClick={() => handleEmptyButtonClick()}>
						Search more on DigsConnect
					</Button>
				</ListingsSectionHeader>
			)}
		</>
	);
};

export default PartnersListingsAccordion;
