import React, { Component } from 'react';

import styled from 'styled-components';

import api from 'api';
import { EventType } from 'constants/events';
import ListingVerificationsV2 from 'components/digs-search-v2/ListingVerificationsV2';
import { analyticsService, PageName } from 'services/analyticsService';
import PartnersListingsAccordion from './PartnersListingsAccordion';
import theme from 'config/theme';
import { Listing } from 'models/listing';
const { colors } = theme;

var qs = require('qs');

const DEFAULT_FILTER_PARAMS = {};

abstract class PartnersSearchComponent<P = {}, S = {}, SS = any> extends Component<P, S, SS> {
	abstract render(): any;
}

type PartnersQueryParameters = {
	locations?: number;
	university?: number;
	nsfas_accredited?: boolean;
	varsity_college_accredited?: boolean;
	aie_accredited?: boolean;
};

type PartnersSearchProps = {
	location: any;
	history: any;
};

type PartnersSearchState = {
	listings: Listing[];
	params: {};
};

class PartnersSearch extends PartnersSearchComponent<PartnersSearchProps, PartnersSearchState> {
	constructor(props: PartnersSearchProps) {
		super(props);
		this.state = {
			listings: [],
			params: DEFAULT_FILTER_PARAMS,
		};
	}

	componentDidMount() {
		analyticsService.trackPage(PageName.Partners);
		let search = this.props.location.search;
		const queryParams = qs.parse(search, { ignoreQueryPrefix: true }) || {};
		this.filterOnQueryParams(queryParams);
		api.events.send({
			event_type: EventType.PartnersSearch,
		});
	}

	filterOnQueryParams = (queryParams: PartnersQueryParameters) => {
		let searchParams: any = {};
		if (queryParams.locations) {
			searchParams = {
				...searchParams,
				locations:
					typeof queryParams.locations === 'string'
						? [+queryParams.locations]
						: queryParams.locations,
				university: undefined,
			};
		}
		if (queryParams.university) {
			searchParams = {
				...searchParams,
				university: queryParams.university,
				locations: undefined,
			};
		}

		searchParams = {
			...searchParams,
			varsity_college_accredited: queryParams.varsity_college_accredited,
			aie_accredited: queryParams.aie_accredited,
		};

		this.setState(
			state => ({
				params: { ...state.params, ...searchParams },
			}),
			() => {
				this.searchListings();
			},
		);
	};

	formatSearchParams = (params: any) => {
		const queryParams = { ...params };

		Object.keys(queryParams).forEach(key => {
			if (typeof queryParams[key] === 'undefined') delete queryParams[key];
			else if (
				queryParams[key] === 0 ||
				queryParams[key] === '' ||
				queryParams[key] === 'undefined'
			)
				delete queryParams[key];
		});

		return Object.keys(queryParams)
			.map((key: any) => {
				if (typeof queryParams[key] !== 'object') {
					return `${key}=${queryParams[key]}`;
				}
				if (queryParams[key] && queryParams[key].length) {
					return queryParams[key].map((item: any) => `${key}=${item}`).join('&');
				}
				return '';
			})
			.join('&');
	};

	trackSearchResults = (numberResults: number) => {
		const params: any = this.state.params;
		api.events.send({
			event_type: 'search_results',
			meta: {
				numberResults,
				partners_title: localStorage.getItem('accordion_title'),
				nsfas_accredited: !!params.nsfas_accredited,
				varsityCollegeAccredited: !!params.varsity_college_accredited,
				aieAccredited: !!params.aie_accredited,
			},
		});
	};

	searchListings = () => {
		const params = this.state.params;
		return api.searchV2.searchDigs(params).then(response => {
			if (response.status !== 200) {
				return;
			}

			const { pathname } = this.props.history.location;
			const searchParams = this.formatSearchParams(params);
			window.history.pushState('state', 'title', `${pathname}?${searchParams}`);

			this.trackSearchResults(response.data.results.length);

			this.setState({
				listings: response.data.results,
			});
		});
	};

	getAccordionTitle(varsityCollegeAccredited?: boolean, aieAccredited?: boolean) {
		if (!!varsityCollegeAccredited) {
			return "The IEE's Varisty College verified properties";
		}
		if (!!aieAccredited) {
			return 'AIE verified properties';
		}
		return 'All properties';
	}

	render() {
		const { listings } = this.state;
		const params: PartnersQueryParameters = this.state.params;

		const isZeroListings = !listings.length;

		let varsityCollegeAccredited = params.varsity_college_accredited;
		let aieAccredited = params.aie_accredited;
		let accordionTitle = this.getAccordionTitle(varsityCollegeAccredited, aieAccredited);
		return (
			<Container>
				<TopInfoItemSet>
					<ListingVerificationsV2
						varsityCollegeAccredited={varsityCollegeAccredited}
						aieAccredited={aieAccredited}
					/>
					<TitleContainer>
						<Title>{accordionTitle}</Title>
						<SubTitle>Powered by DigsConnect</SubTitle>
					</TitleContainer>
				</TopInfoItemSet>
				<PartnersListingsAccordion
					listings={listings}
					hasZeroListings={isZeroListings}
					university={params.university}
					locations={params.locations}
				/>
			</Container>
		);
	}
}

export default PartnersSearch;

const TopInfoItemSet = styled.div`
	display: flex;
	align-items: flex-start;
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 9px;
`;

const SubTitle = styled.div`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 9px;
	color: ${colors.pink};
`;

const Container = styled.div`
	margin: 24px 24px 24px;
`;

const TitleContainer = styled.div`
	margin-left: 24px;
`;
